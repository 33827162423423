<template>
  <div>
    <CCard accent-color="primary">
      <CCardHeader color="white">
        <b class="text-dark">{{ tableTitle }}</b>
      </CCardHeader>
      <CCardBody>
        <!-- section search -->
        <BlockColumn>
          <BlockItem :size="'1-2'">
            <BlockInfoDatePicker 
              :title="$t('date')" 
              v-model="selectedDate" 
              :disabled="false"
            />
          </BlockItem>
        </BlockColumn>

        <CDataTable
          class="mb-0 table-outline"
          hover
          :items="tableItems"
          :fields="tableFields"
          head-color="light"
          external
          :items-per-page-select="{label: $t('items_page')}"
          :items-per-page="perPage"
          :currentPage="currentPage"
          :loading="loading"
          @pagination-change="handlePagination"
        >
          <template #plate="{ item }">
            <td> 
              {{ item.license_plate ? item.license_plate : "" }}
            </td>
          </template>
          <template #homePlantId="{ item }">
            <td class="text-center">
              {{ item.home_plant_external_id ? item.home_plant_external_id : "" }}
            </td>
          </template> 
            <template #homePlant="{ item }">
            <td class="text-center">
              {{ item.home_plant_name ? item.home_plant_name : "" }}
            </td>
          </template>
          <template #callInStatus="{ item }">
            <td class="text-center">
              {{ item.call_in_status ? item.call_in_status : "" }}
            </td>
          </template>
          <template #callInPlantId="{ item }">
            <td class="text-center">
              {{ item.call_in_plant_external_id ? item.call_in_plant_external_id : "" }}
            </td>
          </template>
          <template #callInPlant="{ item }">
            <td class="text-center">
              {{ item.call_in_plant_name ? item.call_in_plant_name : "" }}
            </td>
          </template>
          <template #callInTime="{ item }">
            <td class="text-center">
              {{ item.call_in_time ? item.call_in_time : "" }}
            </td>
          </template>
        </CDataTable>
        <CPagination
          :activePage.sync="currentPage"
          :pages="totalPages"
          :active-page="currentPage"
          @update:activePage ="loadData"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
const moment = require("moment");

import BlockColumn from "./BlockColumn";
import BlockItem from "./BlockItem";
import BlockInfoDatePicker from "./BlockInfoDatePicker";
import "vue2-datepicker/index.css";
export default {
  name: "ListCarrierConvocations",
  components: {
    BlockColumn,
    BlockItem,
    BlockInfoDatePicker
  },
  props: {
    tableItems: Array,
    totalPages: Number,
  },
  data() {
    return {
      tableTitle: this.$i18n.t("trucks"),
      tableFields: [
        { key: "plate", label: this.$t('truck_plate'), _classes: "text-left" },
        { key: "homePlantId",label: this.$t('home_plant'), _classes: "text-center" },
        { key: "homePlant", label: this.$t('home_plant_name'), _classes: "text-center" },
        { key: "callInStatus", label: this.$t('status'), _classes: "text-center" },
        { key: "callInPlantId", label: this.$t('call_in_plant'), _classes: "text-center" },
        { key: "callInPlant", label: this.$t('call_in_plant_name'), _classes: "text-center" },
        { key: "callInTime", label: this.$t('call_in_time'), _classes: "text-center" },
      ],
      selectedDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      perPage: 10, 
      currentPage: 1, 
      loading: false,
      carrier: JSON.parse(localStorage.getItem('carrierInfo')),
    };
  },
  computed: {
  },
  watch: {
    selectedDate() {
      const self = this;
      self.currentPage = 1;
      self.loadData();
    }
  },  
  created() {
  },
  mounted() {
    const self = this;
    self.loadData()
  },
  methods: {
    handlePagination( itemsPerPage ) {
      const self = this;
      self.perPage = itemsPerPage;
      self.loadData();
    },
    loadData() {
      const self = this;
      self.$emit("load-convocations", {
        selectedDate: moment(self.selectedDate).format('YYYY-MM-DD'),
        carrierId: self.carrier ? self.carrier.id : null,
        perPage: self.perPage,
        currentPage: self.currentPage
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>