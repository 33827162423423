<template>
  <div>
    <BlockUI
      id="loading"
      :message="$t('loading')"
      v-if="loading"
      html="<i class='fa fa-cog fa-spin fa-2x fa-fw'></i>"
    />

    <CRow>
      <CCol>
        <ListCarrierConvocations
          v-bind:tableItems="listCarrierConvocations"
          v-bind:totalPages="totalPages"
          @load-convocations="loadData"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import ListCarrierConvocations from "../components/ListCarrierConvocations.vue";

export default {
  name: "CarrierConvocations",
  components: {
    ListCarrierConvocations,
  },
  data() {
    return {
      loading: true,
      listCarrierConvocations: [],
      totalPages: 0,
      totalItems: 0,
    };
  },
  methods: {
    async loadData(filter) {
      const self = this;
      self.loading = true;
      
      try {
        const res = await self.$http.get(`apiConsole/console/getCarrierConvocations/${filter.carrierId}/${filter.selectedDate}/${filter.currentPage}/${filter.perPage}`);
        self.totalItems = res.data.totalVehicles;
        self.totalPages = Math.ceil(self.totalItems / filter.perPage);
        self.listCarrierConvocations = res.data.vehicles;
      } catch (e) {
        console.error(e);
      }
      self.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>